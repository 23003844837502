import $ from 'jquery';

export default function Accordion(el) {
    this.activeClassName = 'active';
    this.animationDuration = 150;

    this.$el = $(el);
    this.$header = this.$el.find('.accordion-header');
    this.$content = this.$el.find('.accordion-content');

    this.bindEvents();
}

Accordion.prototype.bindEvents = function bindEvents() {
    this.$header.on('click', () => {
        console.log('test');
        if (this.$el.hasClass(this.activeClassName)) {
            this.$content.slideUp(this.animationDuration);
        } else {
            this.$content.slideDown(this.animationDuration);
        }
        this.$el.toggleClass(this.activeClassName);
    });
};
