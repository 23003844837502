import $ from 'jquery';

// window.googleMapStyles = [
//         {
//             "featureType": "landscape",
//             "stylers": [
//                 {
//                     "hue": "#FFBB00"
//                 },
//                 {
//                     "saturation": 43.400000000000006
//                 },
//                 {
//                     "lightness": 37.599999999999994
//                 },
//                 {
//                     "gamma": 1
//                 }
//             ]
//         },
//         {
//             "featureType": "road.highway",
//             "stylers": [
//                 {
//                     "hue": "#FFC200"
//                 },
//                 {
//                     "saturation": -61.8
//                 },
//                 {
//                     "lightness": 45.599999999999994
//                 },
//                 {
//                     "gamma": 1
//                 }
//             ]
//         },
//         {
//             "featureType": "road.arterial",
//             "stylers": [
//                 {
//                     "hue": "#FF0300"
//                 },
//                 {
//                     "saturation": -100
//                 },
//                 {
//                     "lightness": 51.19999999999999
//                 },
//                 {
//                     "gamma": 1
//                 }
//             ]
//         },
//         {
//             "featureType": "road.local",
//             "stylers": [
//                 {
//                     "hue": "#FF0300"
//                 },
//                 {
//                     "saturation": -100
//                 },
//                 {
//                     "lightness": 52
//                 },
//                 {
//                     "gamma": 1
//                 }
//             ]
//         },
//         {
//             "featureType": "water",
//             "stylers": [
//                 {
//                     "hue": "#0078FF"
//                 },
//                 {
//                     "saturation": -13.200000000000003
//                 },
//                 {
//                     "lightness": 2.4000000000000057
//                 },
//                 {
//                     "gamma": 1
//                 }
//             ]
//         },
//         {
//             "featureType": "poi",
//             "stylers": [
//                 {
//                     "hue": "#00FF6A"
//                 },
//                 {
//                     "saturation": -1.0989010989011234
//                 },
//                 {
//                     "lightness": 11.200000000000017
//                 },
//                 {
//                     "gamma": 1
//                 }
//             ]
//         }
//     ];

window.initMap = () => {
    let centerPos;
    let zoom;

    let mapMarkers = [];

    const markers = [
        // Leipzig
        {
            id: 'leipzig-1',
            title: "Jugendkulturzentrum Heizhaus",
            pos: {lat: 51.320675, lng: 12.300037},
            address: 'Alte Salzstraße 63, 04209 Leipzig',
            link: 'https://heizhaus-leipzig.de/',
            link_human: 'heizhaus-leipzig.de'
        },
        {
            id: 'leipzig-2',
            title: "Kollektiv 27",
            pos: {lat: 51.343427, lng: 12.412441},
            link: 'https://www.facebook.com/p/Kollektiv27-100037891977098',
            link_human: 'facebook.com/p/Kollektiv27-100037891977098'
        },
        {
            id: 'leipzig-3',
            title: "Graffitiverein Leipzig e.V.",
            pos: {lat: 51.310296, lng: 12.371348},
            address: 'Kochstraße 132, 04277 Leipzig',
            link: 'https://www.facebook.com/graffitiverein',
            link_human: 'facebook.com/graffitiverein'
        },
        // Oschatz
        {
            id: 'oschatz-1',
            title: "Jugendhaus Oschatz",
            pos: {lat: 51.30243, lng: 13.1080779},
            address: 'Lichtstraße 2, 04758 Oschatz',
            link: 'https://www.sprungbrett-riesa.de/jugendhaus-oschatz/',
            link_human: 'sprungbrett-riesa.de/jugendhaus-oschatz'
        },
        // Riesa
        {
            id: 'riesa-1',
            title: "Hot Wheels e.V.",
            pos: {lat: 51.311703, lng: 13.267059},
            address: 'N.A.'
        },
        {
            id: 'riesa-2',
            title: "Outlaw e.V.",
            pos: {lat: 51.3099796, lng: 13.2904638},
            address: 'Bahnhofstraße 44, 01587 Riesa',
            link: 'https://www.outlaw-ggmbh.de/einrichtung/offenes-jugendhaus-riesa',
            link_human: 'outlaw-ggmbh.de/einrichtung/offenes-jugendhaus-riesa'
        },
        {
            id: 'riesa-3',
            title: "Sprungbrett e.V.",
            pos: {lat: 51.3182964, lng: 13.2805254},
            address: 'Hafenstraße 2, 01591 Riesa',
            link: 'https://www.sprungbrett-riesa.de/',
            link_human: 'sprungbrett-riesa.de'
        },
        // Großenhain
        {
            id: 'grossenhain-1',
            title: "Roll-Laden e.V.",
            pos: {lat: 51.2986608, lng: 13.5394218},
            address: 'Trendsporthalle Großenhain, Hohe Straße 29, 01558 Großenhain',
            link: 'https://rollladen.jottplus.de/',
            link_human: 'rollladen.jottplus.de'
        },
        // Dresden
        {
            id: 'dresden-1',
            title: "Dresden Rollt e.V.",
            pos: {lat: 51.018729, lng: 13.7844446},
            address: 'Lohrmannstraße 22, 01237 Dresden',
            link: 'https://dresdenrollt.de/',
            link_human: 'dresdenrollt.de'
        },
        {
            id: 'dresden-2',
            title: "Feed My Soul (Einzelhandel)",
            pos: {lat: 51.0675384, lng: 13.74719},
            address: 'Alaunstraße 53, 01099 Dresden',
            link: 'https://www.feedmysoul.de',
            link_human: 'feedmysoul.de'
        },
        {
            id: 'dresden-3',
            title: "Rollaroma GmbH (Einzelhandel)",
            pos: {lat: 51.0487487, lng: 13.74009},
            address: 'Ringstraße 5, 01067 Dresden'
        },
        {
            id: 'dresden-4',
            title: "Skate-Academy Dresden",
            pos: {lat: 51.018729, lng: 13.7844446},
            address: 'Lohrmannstraße 22, 01237 Dresden',
            link: 'https://skateboardacademy.de/',
            link_human: 'skateboardacademy.de'
        },
        {
            id: 'dresden-5',
            title: "Jugendhaus GAME",
            pos: {lat: 51.0064317, lng: 13.7866581},
            address: 'Gamigstraße 32, 01239 Dresden',
            link: 'https://www.mobsued.de/jugendhaus-game',
            link_human: 'mobsued.de/jugendhaus-game'
        },
        {
            id: 'dresden-6',
            title: "Mobile Jugendarbeit Dresden-Süd e.V.",
            pos: {lat: 51.0192435, lng: 13.7789518},
            address: 'Bernhard-Kretzschmar-Straße 26, 01219 Dresden',
            link: 'https://www.vsp-dresden.org/',
            link_human: 'vsp-dresden.org'
        },
        {
            id: 'dresden-7',
            title: "84'til Zentrum für Urbane Kultur",
            pos: {lat: 51.095033, lng: 13.6930871},
            address: 'Meißner Straße 21, 01445 Radebeul',
            link: 'https://www.84til.de/',
            link_human: '84til.de'
        },
        {
            id: 'dresden-8',
            title: "Saxons, Tanzkollektiv",
            pos: {lat: 51.0755381, lng: 13.7378186},
            address: 'Dresden-Neustadt'
        },
        // Bautzen
        {
            id: 'bautzen-1',
            title: "Mehrgenerationenhaus Gesundbrunnen",
            pos: {lat: 51.1946448, lng: 14.4442978},
            address: 'Otto-Nagel-Straße 3, 02625 Bautzen',
            link: 'https://www.mgh-bautzen-gesundbrunnen.de/',
            link_human: 'mgh-bautzen-gesundbrunnen.de'
        },
        {
            id: 'bautzen-2',
            title: "Bautzen Rollt e.V.",
            pos: {lat: 51.1802806, lng: 14.4143849},
            address: 'Leibnizstraße 13, 02625 Bautzen',
            link: 'https://bautzenrolltev.wordpress.com/',
            link_human: 'bautzenrolltev.wordpress.com'
        },
        // Chemnitz
        {
            id: 'chemnitz-1',
            title: "Alternatives Jugendzentrum e.V.",
            pos: {lat: 50.8608777, lng: 12.9230729},
            address: 'Chemnitztalstraße 54, 09114 Chemnitz',
            link: 'https://www.ajz-chemnitz.de/',
            link_human: 'ajz-chemnitz.de'
        },
        {
            id: 'chemnitz-2',
            title: "Skatehalle Chemnitz Druckbude",
            pos: {lat: 50.8512982, lng: 12.9185779},
            address: 'Schönherrstraße 8, 09113 Chemnitz',
            link: 'https://www.skatehalle-chemnitz.de/web/news',
            link_human: 'skatehalle-chemnitz.de/web/news'
        },
        // Borna
        {
            id: 'borna-1',
            title: "Freizeitzentrum Borna, Kindervereinigung Leipzig e.V.",
            pos: {lat: 51.1252903, lng: 12.5051466},
            address: 'Schulstraße 19, 04552 Borna',
            link: 'https://www.freizeitzentrum-borna.de/',
            link_human: 'freizeitzentrum-borna.de'
        },
        // Frohburg
        {
            id: 'frohburg-1',
            title: "Offener Treff Frohburg, Kindervereinigung Leipzig e.V.",
            pos: {lat: 51.0675336, lng: 12.559274},
            address: 'Wolfslückenweg 13, 04654 Frohburg',
            link: 'https://www.kv-leipzig.de/freizeittreff/oft-frohburg/',
            link_human: 'kv-leipzig.de/freizeittreff/oft-frohburg'
        },
        // Döbeln
        {
            id: 'doebeln-1',
            title: "Treibhaus Döbeln e.V.",
            pos: {lat: 51.121116, lng: 13.110745},
            address: 'Bahnhofstraße 56, 04720 Döbeln',
            link: 'https://treibhaus-doebeln.de/',
            link_human: 'treibhaus-doebeln.de'
        },
        {
            id: 'doebeln-2',
            title: "Skateforce Döbeln",
            pos: {lat: 51.121116, lng: 13.110745},
            address: 'Roßweiner Straße, 04720 Döbeln',
            link: 'https://treibhaus-doebeln.de/skate-force-doebeln-sfd/',
            link_human: 'treibhaus-doebeln.de/skate-force-doebeln-sfd'
        },
        {
            id: 'doebeln-3',
            title: "Haus der Vielfalt",
            pos: {lat: 51.1203492, lng: 13.1176842},
            address: 'Zwingerstraße 3/4, 04720 Döbeln',
            link: 'https://treibhaus-doebeln.de/haus-der-vielfalt/',
            link_human: 'treibhaus-doebeln.de/haus-der-vielfalt'
        },
        // Plauen
        {
            id: 'plauen-1',
            title: "Skatehalle Rollkantine",
            pos: {lat: 50.5169302, lng: 12.1225799},
            address: 'Hans-Sachs-Straße 49, 08525 Plauen',
            link: 'https://www.plauen.de/Kurzmen%C3%BC/Adressen/Skatehalle-Plauen-Rollkantine-.php?object=tx,3317.1.1&ModID=9&FID=3317.3035.1&NavID=3317.2209.1',
            link_human: 'plauen.de'
        },
        {
            id: 'plauen-2',
            title: "Post Sport Verein Plauen e.V.",
            pos: {lat: 50.494308, lng: 12.127802},
            address: 'Gartenstraße 33, 08523 Plauen',
            link: 'http://www.post-sv-plauen.de/',
            link_human: 'post-sv-plauen.de'
        },
        // Bischofswerda
        {
            id: 'bischofswerda-1',
            title: "Mosaika e.V.",
            pos: {lat: 51.121144, lng: 14.1903163},
            address: 'Ernst-Thälmann-Straße 3, 01877 Bischofswerda',
            link: 'https://mosaika-biw.de/',
            link_human: 'mosaika-biw.de'
        },
        {
            id: 'bischofswerda-2',
            title: "Regenbogen e.V.",
            pos: {lat: 51.1235398, lng: 14.1887917},
            address: 'Belmsdorfer Straße 28, 01877 Bischofswerda',
            link: 'https://regenbogen-biw.de/',
            link_human: 'regenbogen-biw.de'
        },
        // Kreischa
        {
            id: 'kreischa-1',
            title: "Grundschule Kreischa (Kirschweg 1A - C)",
            pos: {lat: 50.9435362, lng: 13.7550524},
            address: 'Kirschweg 1A-C, 01731 Kreischa',
            link: 'https://www.grundschule-kreischa.de/inhalte/grundschule_kreischa/_inhalt/unsere_schule/unsere_schule',
            link_human: 'grundschule-kreischa.de'
        },
        // Ehrenberg
        {
            id: 'ehrenberg-1',
            title: "Tannertschule",
            pos: {lat: 50.9870432, lng: 14.1570887},
            address: 'Hauptstraße 74, 01848 Hohnstein',
            link: 'https://cms.sachsen.schule/tannert/adolf-tannert-schule-hohnstein/',
            link_human: 'cms.sachsen.schule/tannert/adolf-tannert-schule-hohnstein'
        },
        // Torgau
        {
            id: 'torgau-1',
            title: "Kulturhaus Torgau",
            pos: {lat: 51.5610166, lng: 13.0039009},
            address: 'Rosa-Luxemburg-Platz 16, 04860 Torgau',
            link: 'https://www.kulturhaus-torgau.de/index.php/Startseite.html',
            link_human: 'kulturhaus-torgau.de'
        },
        {
            id: 'torgau-2',
            title: "Jugendteam Torgau",
            pos: {lat: 51.5360965, lng: 12.9640495},
            address: 'Postfach 11 25, 04851 Torgau',
            link: 'https://www.jugendteam-torgau.de/',
            link_human: 'jugendteam-torgau.de'
        },
        // Görlitz
        {
            id: 'goerlitz-1',
            title: "Kulturraum Oberlausitz-Niederschlesien, Netzwerkstelle Kulturelle Bildung",
            pos: {lat: 51.1584701, lng: 14.9819519},
            address: 'Kulturraum Oberlausitz-Niederschlesien, c/o Landratsamt Görlitz, Kultursekretariat, Lunitz 10, 02826 Görlitz'
        },
        {
            id: 'goerlitz-2',
            title: "15° BMX Halle Görlitz",
            pos: {lat: 51.12984, lng: 14.9676851},
            address: 'Am Bahnhof Weinhübel 2, 02827 Görlitz',
            link: 'https://kuehlhaus-goerlitz.de/kultur-freizeit/bmx-skate-halle/',
            link_human: 'kuehlhaus-goerlitz.de/kultur-freizeit/bmx-skate-halle'
        },
    ];

    centerPos = {lat: 51.005016, lng: 13.247886};
    zoom = window.innerWidth <= 768 ? 7 : 8;

    const el = document.getElementById('google-map');
    if (el === null) {
        return;
    }

    const infoWindowAriaLabelPreset = 'Information und Adresse zu ';

    const map = new google.maps.Map(el, {
        zoom: zoom,
        zoomControl: false,
        streetViewControl: false,
        scaleControl: false,
        rotateControl: false,
        panControl: false,
        mapTypeControl: false,
        fullScreenControl: false,
        center: centerPos,
        // styles: window.googleMapStyles
    });

    const infoWindow = new google.maps.InfoWindow({
        content: '',
        ariaLabel: infoWindowAriaLabelPreset,
    });

    markers.forEach((marker, index) => {
        let mapMarker = new google.maps.Marker({
            id: marker.id,
            title: marker.title,
            position: marker.pos,
            address: marker.address,
            link: marker.link,
            linkHuman: marker.link_human,
            map: map
        });

        mapMarker.addListener("click", () => {
            const infoWindowContent =
                '<div class="map-info-window">' +
                '<strong class="map-info-window__title">' + mapMarker.title + '</strong>' +
                '<div class="map-info-window__content">' +
                "<p>" +
                (mapMarker.address ? mapMarker.address : "") +
                (mapMarker.link ? '<a href="' + mapMarker.link + '" rel="noopener" target="_blank">' + mapMarker.linkHuman + "</a>" : "") +
                "</p>" +
                "</div>" +
                "</div>";
            infoWindow.setContent(infoWindowContent);

            infoWindow.open({
                anchor: mapMarker,
                map,
            });

            mapMarker.background = '#FBBC04';
        });

        mapMarkers.push(mapMarker);
    });

    const locationListItems = document.getElementsByClassName('location-list__item');
    for (let item of locationListItems) {
        $(item).on('click', (e) => {
            e.preventDefault();
            mapMarkers.forEach(mapMarker => {
                    if (mapMarker.id === item.id) {
                        google.maps.event.trigger(mapMarker, 'click');
                    }
                }
            );
        });
    }
};

export default function GoogleMap() {
    this.bindEvents();
}

GoogleMap.prototype.bindEvents = function bindEvents() {
    $('.google-map-overlay').find('.confirm').on('click', () => {
        $('.google-map-overlay').hide();
        $('.map').addClass('sticky');
        $('.location-list')[0].removeAttribute('inert');

        // Load google maps
        let script = document.createElement('script');
        script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyASYl2tlLxjdL-Z6PBtm_QPa-lj9njrI_o&callback=initMap';
        script.defer = true;

        // Append the 'script' element to 'head'
        document.head.appendChild(script);
    });
};
