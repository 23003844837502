import 'reset-css';
import '../styles/app.scss';
import '../img/komet-03.svg';
import '../img/smgi_zusatz_mittelherkunft_intm.jpg';
import '../img/favicon/apple-touch-icon.png';
import '../img/favicon/favicon-16x16.png';
import '../img/favicon/favicon-32x32.png';
import Accordion from "./lib/Accordion";
import $ from 'jquery';
import GoogleMap from "./lib/GoogleMap";

if (process.env.NODE_ENV !== 'production') {
    console.log('Looks like we are in development mode!');
}

window.initComponents = () => {
    let $accordions = $('.accordion');
    $.each($accordions, (index, value) => {
        new Accordion(value);
    });

    new GoogleMap();
}

initComponents();

